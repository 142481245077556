<script lang="ts" setup>
import _ from 'lodash';
import { formatMonthDateTime } from '~/utils/date.util';

defineProps({
  footerData: { required: false, type: Object },
  headers: { required: true, default: () => [], type: Array },
  items: { required: true, default: () => [], type: Array },
});

const getValue = (item: any, col: any) => {
  if (
    _.get(item, col.value) === null
    || _.get(item, col.value) === undefined
    || Number.isNaN(_.get(item, col.value))
  ) {
    return '-';
  }
  if (col.type === 'date') {
    return formatMonthDateTime(_.get(item, col.value));
    // try {
    //   return formatMonthDateTime(_.get(item, col.value));
    // }
    // catch (e) {
    //   // adding this catch method since this class might be used by non-component type class
    //   return formatMonthDateTime(_.get(item, col.value));
    // }
  }

  if (col.type === 'list') {
    if (Array.isArray(_.get(item, col.value))) {
      return _.get(item, col.value).join(', ');
    }
    return _.get(item, col.value, '-');
  }

  if (col.type === 'list_string') {
    let listString = _.get(item, col.value);
    if (listString) {
      try {
        // @eslint-disable-next-line no-useless-escape
        listString = listString.replace(/'/g, '"');
        const parsedArray = JSON.parse(listString);
        if (Array.isArray(parsedArray)) {
          const listArrJoined = parsedArray.join(', ');
          return listArrJoined || '-';
        }
        else {
          return listString || '-';
        }
      }
      catch (e) {
        return listString || '-';
      }
    }
    else {
      return '-';
    }
  }

  if (col.type === 'currency') {
    const value = _.get(item, col.value, col.defaultValue || 0);
    if (isNaN(value)) {
      return '-';
    }
    const currency = col.currency || 'USD';
    const locale = col.locale || 'en-US';
    return new Intl.NumberFormat(locale, {
      style: 'currency',
      currency,
      maximumFractionDigits: 2,
      minimumFractionDigits: 2,
    }).format(value);
  }

  if (col.type === 'day') {
    const day = _.get(item, col.value, '-');
    if (day === '-') {
      return '-';
    }
    return `${day} days`;
  }

  return _.get(item, col.value, '-');
};
</script>

<template>
  <v-table class="custom-table">
    <template #default>
      <thead>
        <tr>
          <th
            v-for="(header, index) in headers"
            :key="index+header.value"
            class="custom-header"
          >
            {{ header.text }}
          </th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="(item, trx) in items"
          :key="`${trx}-${item.id}`"
        >
          <!-- Ensure each item has a unique key -->
          <td
            v-for="(header, tdx) in headers"
            :key="trx+tdx+header.value"
            :class="`custom-cell custom-cell-type-${header.type}`"
          >
            {{ getValue(item, header) }}
          </td>
        </tr>
      </tbody>
      <tfoot v-if="footerData">
        <tr>
          <td
            v-for="(header, index) in headers"
            :key="index+header.value"
            class="custom-header"
          >
            <span v-if="header.footer"> {{ footerData[header.footer] }}</span>
          </td>
        </tr>
      </tfoot>
    </template>
  </v-table>
</template>

<style lang="scss">
.custom-table {
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
  border: 1px solid #ddd;
  border-radius: 8px;
  overflow: hidden;
}

.custom-header {
  background-color: #f1f3f5;
  font-weight: bold;
  text-align: left;
  padding: 12px;
  border: 1px solid #ddd;
  color: #5c6b77;
}

.custom-cell {
  padding: 12px;
  text-align: left;
  border: 1px solid #ddd;
  color: #2e3b4e;

  &-type-date, &-type-Currency, &-type-Day {
    white-space: nowrap;
  }

  &-type-exception {
    color: red
  }
}

.custom-table tbody tr:last-child .custom-cell {
  border-bottom: 1px solid #ddd;
}
</style>
