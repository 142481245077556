import _ from 'lodash';
import * as XLSX from 'xlsx';
import type { Zippable } from 'fflate';
import { zipSync } from 'fflate';
import SimpleTable from '~/components/common/SimpleTable.vue';

export interface FileBlob {
  name: string
  blob: Blob
}

export enum UploadStatusEnum {
  CONTAINER_CUSTOM_UPLOADED_SUCCESS,
  CONTAINER_UPLOADED_PARTIALLY,
  CONTAINER_SUCCESS_CUSTOM_UPLOADED_PARTIALLY,
}

export enum UploadTypesEnum {
  BL = 'BL',
  CONTAINER = 'CONTAINER',
}

export interface ExcelSheet {
  sheetName: string
  headers: any
  data: any[]
  footer?: object
}

export const jsonToCsvDownload = (fileName: string, arrayOfJson: Array<any>): void => {
  const link = document.createElement('a');
  link.setAttribute('href', `data:text/csv;charset=utf-8,${getCsvFromJson(arrayOfJson)}`);
  link.setAttribute('download', fileName);
  link.style.visibility = 'hidden';
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

const getCsvFromJson = (arrayOfJson: Array<any>): any => {
  const replacer = (_key: any, value: null) => value === null ? '' : value;
  const header = Object.keys(arrayOfJson[0]);
  let csv: any = arrayOfJson.map(row => header.map(fieldName => JSON.stringify(row[fieldName], replacer)).join(','));
  csv.unshift(header.join(','));
  csv = csv.join('\n');
  return csv;
};

export const getCustomFields = (row: any, customFieldsMappings: any, orgCustomFields: any) => {
  const orgCustomFieldsReturn: any = [];
  orgCustomFields.forEach((orgCustomField: any) => {
    if (customFieldsMappings[orgCustomField.label]) {
      orgCustomField.user_data = row[customFieldsMappings[orgCustomField.label].mappedKey];

      orgCustomField.children.forEach((child: any) => {
        if (customFieldsMappings[child.label]) {
          child.user_data = row[customFieldsMappings[child.label].mappedKey];
        }
        else {
          child.user_data = null;
        }
      });
    }
    else {
      orgCustomField.user_data = null;
    }
    orgCustomFieldsReturn.push(_.cloneDeep(orgCustomField));
  });
  return orgCustomFieldsReturn;
};

const tableToExcel = (headers: any[], data: any[], sheetName: string, footerData?: any) => {
  const aoa: any[][] = [];

  // Write headers
  const headerRow: any[] = headers.map(header => header.text);
  aoa.push(headerRow);

  // Write data
  data.forEach((item) => {
    const row: any[] = headers.map((header) => {
      return new SimpleTable.getValue(item, header);
    });
    aoa.push(row);
  });

  // Write footers if exist
  if (footerData) {
    const row: any[] = headers.map((header) => {
      if (header.footer) {
        return footerData[header.footer];
      }
      return null;
    });
    aoa.push(row);
  }

  const ws = XLSX.utils.aoa_to_sheet(aoa);

  return { sheetName, ws };
};

export const generateMultiSheetsExcelBlob = (sheetData: ExcelSheet[]) => {
  const wb = XLSX.utils.book_new();

  sheetData.forEach((sheet) => {
    const { headers, data, sheetName, footer } = sheet;
    const { ws } = tableToExcel(headers, data, sheetName, footer);
    XLSX.utils.book_append_sheet(wb, ws, sheetName);
  });

  const wbout = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
  return new Blob([wbout], { type: 'application/octet-stream' });
};

export const zipFilesAndDownload = async (files: FileBlob[], zipName: string) => {
  const zippable: Zippable = {};
  for (const file of files) {
    const arrayBuffer = await file.blob.arrayBuffer();
    zippable[file.name] = new Uint8Array(arrayBuffer);
  }

  const zipped = zipSync(zippable);
  const blob = new Blob([zipped], { type: 'application/zip' });
  const blobURL = URL.createObjectURL(blob);

  const link = document.createElement('a');
  link.href = blobURL;
  link.download = zipName;
  link.style.visibility = 'hidden';
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};
